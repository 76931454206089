@font-face {
  font-family: "CustomFont";
  src: url("./font/AvenirArabic-Book.otf");
  font-weight: 100;
  }
  @font-face {
    font-family: "CustomFont";
    src: url("./font/AvenirArabic-Heavy.otf");
    font-weight: 400
    
    }
    @font-face {
      font-family: "CustomFont";
      src: url("./font/AvenirArabic-Light1.otf");
      font-weight: 200;
      
      }
      @font-face {
        font-family: "CustomFont";
        src: url("./font/AvenirArabic-Medium.otf");
        font-weight: normal;
        
        }




body {
  margin: 0;
  font-family: 'CustomFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: 'hidden';
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.activer{
  color: '#fff' !important;
  border: 1px solid #000 !important;
}

.loder{
 
  animation: loader 5s infinite linear;
}


@keyframes loader {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
    
  }
}




