input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #ffffffb9;
}

form{
    width: 80%;
    margin: 20px auto 30px auto;
}

.sup{
    background-color: #2187c6;
}

.map{
    width: 100%;
    height: 400px;
    margin-bottom: 30px;
    color: #d7dcdc;
    border: black 1px solid;
}